@import url('https://fonts.googleapis.com/css?family=Open+Sans|Poppins:900i&display=swap');
body {
  margin: 0;
  background-color: #211f20;
  color: white;
  /* overflow: hidden; */
  /* height: 100vh; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
  
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 5em;
  margin: 0px;
}

.logo {
  width: 128px;
  border: gray 1px solid;
  border-radius: 20px;
  margin-bottom: 20px;
}

.App {
display: flex;
flex-direction: column;
/* height: 100vh; */
}

.mockupA {
  /* width: 700px */
}

.mainParent {
  display: flex;
  margin-left: 100px;
  margin-right: 100px;
  flex-direction: row;
  flex: 1
}

.mainParent div {
  flex: 1;
  /* background-color: aquamarine; */
}
.promoText {
  padding-top: 150px;
  padding-left: 50px;
}

.mockupContainer img{
 width: 100%
}
.iTunes {
  margin-top: 20px;
  display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-05-24&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;
margin-right: 16px;
}

.googlePlay {
  margin-top: 20px;
  display:inline-block;overflow:hidden;
  background: url(https://cdn.worldvectorlogo.com/logos/google-play-badge.svg) no-repeat;
    background-size: contain;
  
  width:135px;height:40px;

}

@media only screen and (max-width: 768px) {
  .iTunes {
    
  margin-right: 8px;
  }
  .promoText {
    
    padding: 0px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    font-size: 3em;
  }
  .mainParent {
margin: 16px;
    flex-direction: column;
  }
}
